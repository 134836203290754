const BASE = "https://accionmaze.io";

const config = {
  BASE: `${BASE}`,
};

export default {
  LOGIN: `${config.BASE}/api/users/login`,
  REGISTER: `${config.BASE}/api/users/register`,
  VALIDATE_SESSION: `${config.BASE}/api/users/validate-session`,
};
